import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import ContentWrapper from "styles/contentWrapper"
import assetfiLogo from "content/assetfi.svg"
import arbiIcon from "content/arbitrum.svg"
import bscIcon from "content/bsc.svg"
import Twitter from "components/icons/twitter"
import Medium from "components/icons/medium"
import Telegram from "components/icons/telegram"
import Website from "components/icons/website"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      padding: 0 0 1rem;
      margin-bottom: 1rem;
      text-align: center;
    }
    .subtitle {
      max-width: 36.68rem;
      margin: auto;
      text-align: center;
    }
  }
`

const StyledFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: 3rem;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`

const StyledCard = styled.div`
  flex: 1 1 100%;
  max-width: 30rem;
  padding: 1rem;
  .card {
    background: #FCFCFC;
    position: relative;
    border: 1px solid #E4E4E4;
    border-radius: 4.36rem;
    height: 100%;
    width: 100%;
    padding: 3rem 1.63rem;
    transform: perspective(100px);
    transition: all 0.3s ease 0s;
    .basic {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        position: relative;
        img {
          height: 2rem;
          width: auto;
        }
      }
      .tags-wrapper {
        height: 3rem;
        display: flex;
        .chain {
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #252525;
          img {
            height: 2rem;
            width: 2rem;
          }
        }
      }
    }
    .name {
      font-size: 2rem;
      line-height: 2.6rem;
      font-weight: 600;
      margin-bottom: 0.3rem;
      color: #000000;
      font-family: ${({ theme }) => theme.fonts.primary};
      margin-top: 1.8rem;
    }
    .price {
      font-weight: 600;
      color: #727480;
      font-size: 1rem;
      line-height: 1.2rem;
    }
    .progress {
      margin-top: 1rem;
      .current-raised {
        .label {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0.2rem;
        }
        .value {
          font-size: 1.3rem;
          color: #49e9fb;
          font-weight: 600;
        }
      }
      .progress-bar {
        margin-top: 2rem;
      }
      .progress-label {
        display: flex;
        justify-content: space-between;
        font-size: 0.7rem;
        font-weight: 600;
        color: #727480;
        line-height: 0.8rem;
      }
    }
    .details {
      margin-top: 3rem;
      .description {
        font-size: 1.30rem;
        line-height: 1.96rem;
        margin-bottom: 2rem;
        height: unset;
      }
      .social {
        margin: 2rem 0;
        a {
          margin-right: 1.8rem;
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 1rem;
          .key {
            font-size: 0.9rem;
            line-height: 1rem;
            color: #656D8B;
          }
          .value {
            color: #252525;
            margin-top: 0.3rem;
            font-weight: 600;
            font-family: ${({ theme }) => theme.fonts.primary};
            font-size: 1rem;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
    &:hover {
      transition: transform 0.3s ease 0s;
      transform: scale(1.02) translate(0px, -10px) !important;
    }
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    flex: 1 1 50%;
    max-width: 50%;
    .card {
      padding: 3.63rem;
      .basic {
        .logo-wrapper {
          img {
            height: 4rem;
            width: auto;
          }
        }
        .tags-wrapper {
          height: 4rem;
          .chain {
            height: 4rem;
            width: 4rem;
            img {
              height: 3rem;
              width: 3rem;
            }
          }
        }
      }
      .details {
        .description {
          height: 13rem;
        }
        ul {
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
`

const Progress = styled.div`
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  background-color: #DFE7EF;
  border-radius: 0.5rem;
  width: 100%;
  margin-bottom: 0.5rem;

  .inner {
    background-color: #49e9fb!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
  }
`
const StyledState = styled.div`
  border-radius: 10rem;
  display: none;
  font-size: 1rem;
  line-height: 1rem;
  align-items: center;
  height: 3rem;
  padding: 0 1.3rem;
  text-transform: uppercase;
  width: fit-content;
  margin-left: 1rem;
  background: #252525;
  color: #FCFCFC;
  .dot {
    display: block;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    background: green;
    margin-right: 0.5rem;
  }
  @media (min-width: 1200px) {
    padding: 0 2rem;
    height: 4rem;
    display: flex;
  }
`
const Idos = ({}) => {
 
  return (
      <StyledSection  id="pools">
        <StyledContentWrapper>
          <h2>Projects Under Review</h2>
          <StyledFlex>
            <StyledCard>
              <div className="card">
                <div className="basic">
                  <div className="logo-wrapper">
                    <img src="https://kakifi.com/home/images-53e5d-logo.png" />
                  </div>
                  <div className="tags-wrapper">
                    {/* <div className="chain">
                      <img src={bscIcon} />
                    </div> */}
                    {/* <StyledState color="#CDF0EA">
                    <span className="dot"></span> Upcoming
                    </StyledState> */}
                  </div>
                </div>
                <div>
                  <div className="name">
                    KAKI Protocol
                  </div>
                  <div className="price">
                   _
                  </div>
                </div>
                <div className="details">
                  <div className="description">
                    KAKI is a multi-chain DeFi protocol, currently deployed on Arbitrum and BSC, and will join more ecosystems in the future.
                    There are two products, the No-loss trading game and the Squid game.
                  </div>
                  <div className="social">
                    <a href="https://t.me/kakiers" target="_blank" rel="nofollow"><Telegram color="#5B37F6"/></a>
                    <a href="https://twitter.com/kakiprotocol" target="_blank" rel="nofollow"><Twitter color="#5B37F6"/></a>
                    <a href="https://kakifi.com" target="_blank" rel="nofollow"><Website color="#5B37F6"/></a>
                  </div>
              {/*     <ul>
                    <li><span className="key">Start date</span><span className="value">_</span></li>
                    <li><span className="key">Hard cap</span><span className="value">_</span></li>
                    <li><span className="key">Round type</span><span className="value">Public</span></li>
                  </ul> */}
                </div>
              </div>
             </StyledCard>
          </StyledFlex>
        </StyledContentWrapper>
      </StyledSection>
  )
}


export default Idos
