import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import ContentWrapper from "styles/contentWrapper"
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';

import leopardIcon from "content/images/leopard.png"
import cougarIcon from "content/images/cougar.png"
import jaguarIcon from "content/images/jaguar.png"
import teamworkIcon from "content/images/teamwork.svg"
import equiteIcon from "content/images/equite.svg"
import innovationIcon from "content/images/innovation.svg"
import spin from "content/blue-sping.png"


const StyledSection = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  margin-top: -5rem;
  .spin {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
  }
  @media (min-width: 600px) {
    .spin {
      width: unset;
    }
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 3rem;
    position: relative;
    h2 {
      position: relative;
      color: white;
      padding: 0 0 1rem;
      margin-bottom: 1rem;
    }
    .subtitle {
      max-width: 36.68rem;
      margin: auto;
      text-align: left;
      color: white;
      opacity: 0.6;
      font-size: 1.3rem;
      line-height: 2rem;
    }
    .slidingVertical {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.7);
      .item {
        text-align: center;
        padding: 1rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        &:last-of-type {
          border-bottom: none;
        }
      }
      .section-subtitle {
        font-size: 1rem;
      }
    }
    @media (min-width: 500px) {
      .slidingVertical {
        font-size: 1.3rem;
        .item {
          padding: 0;
          border: none;
          text-align: left;
        }
      }
      .section-subtitle {
        font-size: 1.71rem;
        font-weight: 500;
        margin-top: 2rem;
        line-height: 2.85rem;
        text-align: left;
      }
      .slidingVertical .item {
        animation: topToBottom 9s linear infinite 0s;
        -ms-animation: topToBottom 9s linear infinite 0s;
        -webkit-animation: topToBottom 9s linear infinite 0s;
        opacity: 0;
        position: absolute;
        left: 0;
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .slidingVertical .item:nth-child(1){
        animation-delay: 3s;
        -ms-animation-delay: 3s;
        -webkit-animation-delay: 3s;
      }
      .slidingVertical .item:nth-child(2){
        animation-delay: 6s;
        -ms-animation-delay: 6s;
        -webkit-animation-delay: 6s;
      }
      
      .slidingVertical .item:nth-child(3){
        animation-delay: 9s;
        -ms-animation-delay: 9s;
        -webkit-animation-delay: 9s;
      }
      @keyframes topToBottom {
        0% { opacity: 0; }
        5% { opacity: 0; transform: translateY(-20px); }
        20% { opacity: 1; transform: translateY(0px); }
        30% { opacity: 1; transform: translateY(0px); }
        45% { opacity: 0; transform: translateY(20px); }
        80% { opacity: 0; }
        100% { opacity: 0; }
      }
  
      /*topToBottom Animation*/
      @-moz-keyframes topToBottom{
        0% { opacity: 0; }
        5% { opacity: 0; -moz-transform: translateY(-20px); }
        20% { opacity: 1; -moz-transform: translateY(0px); }
        30% { opacity: 1; -moz-transform: translateY(0px); }
        45% { opacity: 0; -moz-transform: translateY(20px); }
        80% { opacity: 0; }
        100% { opacity: 0; }
      }
      @-webkit-keyframes topToBottom{
        0% { opacity: 0; }
        5% { opacity: 0; -webkit-transform: translateY(-20px); }
        20% { opacity: 1; -webkit-transform: translateY(0px); }
        30% { opacity: 1; -webkit-transform: translateY(0px); }
        45% { opacity: 0; -webkit-transform: translateY(20px); }
        80% { opacity: 0; }
        100% { opacity: 0; }
      }
      @-ms-keyframes topToBottom{
        0% { opacity: 0; }
        5% { opacity: 0; -ms-transform: translateY(-20px); }
        20% { opacity: 1; -ms-transform: translateY(0px); }
        30% { opacity: 1; -ms-transform: translateY(0px); }
        45% { opacity: 0; -ms-transform: translateY(20px); }
        80% { opacity: 0; }
        100% { opacity: 0; }
      }
    }
  }
`



const StyledAdvantages = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: 3rem;
  width: 100%;
  &:first-of-type {
    margin-top: 7rem;
  }
  .card-wrapper {
    flex: 1 1 100%;
    width: 100%;
    padding: 1rem;
    .card {
      background: rgba(255, 255, 255, 0.04);
      border-radius: 1.8rem;
      box-sizing: border-box;
      padding: 3rem 1rem;
      .logo-wrapper {
        width: 8rem;
        height: 8rem;
        margin: auto;
        border-radius: 50%;
        background: rgba(255,255,255,0.18);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: auto;
          width: 5rem;
        }
      }
      .title {
        color: white;
        font-family: ${({ theme }) => theme.fonts.poppins};
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 2.3rem;
        margin-top:  2.8rem;
      }
      .description {
        font-family: ${({ theme }) => theme.fonts.poppins};
        color: white;
        opacity: 0.6;
        span {
          opacity: 1;
          font-weight: 700;
        }
      }
    }
    .card.inline {
      display: flex;
      padding: 2rem 1.5rem;
      text-align: left;
      .logo-wrapper {
        width: 4.5rem;
        height: 4.5rem;
        img {
          height: 2.2rem;
          width: auto;
        }
      }
      .card__right {
        margin-left: 1.5rem;
        .title {
          margin-top: 0;
          font-size: 1.3rem;
        }
      }
    }
    @media (min-width: 1200px) {
      flex-direction: row;
      flex: 1 1 33.33333333%;
      max-width: 33.33333333%;
    }
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    .card-wrapper {
      .card {
        padding: 4.45rem;
        .logo-wrapper {
          width: 8rem;
          height: 8rem;
          img {
            height: 5rem;
            width: auto;
          }
        }
      }
    }
  }
`


const Tiers = ({}) => {
  const [openedIndex, setOpendIndex] = useState(0)
  const [ tRef, tInView, tEntry ] = useInView({threshold: 0});
  const [ iRef, iInView, iEntry ] = useInView({threshold: 0});
  const [ cRef, cInView, cEntry ] = useInView({threshold: 0});
  const [ aRef, aInView, aEntry ] = useInView({threshold: 0});
  const [ bRef, bInView, bEntry ] = useInView({threshold: 0});
  const [ dRef, dInView, dEntry ] = useInView({threshold: 0});
  const tControls = useAnimation()
  const iControls = useAnimation()
  const cControls = useAnimation()
  const aControls = useAnimation()
  const bControls = useAnimation()
  const dControls = useAnimation()
  useEffect(() => {
    const sequence = async () => {
      if (iInView) iControls.start({ opacity: 1, y: 0 })
      if (tInView) tControls.start({ opacity: 1, y: 0 })
      if (cInView) cControls.start({ opacity: 1, y: 0 })
      if (aInView) aControls.start({ opacity: 1, y: 0 })
      if (bInView) bControls.start({ opacity: 1, y: 0 })
      if (dInView) dControls.start({ opacity: 1, y: 0 })
    }
    sequence()
  }, [tControls, iControls, cControls, tInView, iInView, cInView, aControls, bControls, dControls, aInView, bInView, dInView])

  return (
    <StyledSection  id="tiers">
      <StyledContentWrapper>
        <h2>Tiers and Staking Mechanism</h2>
        <span className="slidingVertical subtitle">
            <span className="item"><span className="inner">Guaranteed Allocation For All Stakers.</span></span>
            <span className="item"><span className="inner">Stake as low as 1 PLG</span></span>
            <span className="item"><span className="inner">Proportionate Allocation Model</span></span>
        </span>
        <StyledAdvantages>
          <motion.div
            ref={iRef}
            initial={{ opacity: 0, y: 20 }}
            animate={iControls}
            className="card-wrapper"
          >
            <div className="card">
              <div className="logo-wrapper">
                <img src={leopardIcon} alt="leopard" />
              </div>
              <div className="title">Leopard Tier</div>
              <div className="description">
                <p>
                  <span>15% </span>of the IDO allocation 
                </p>
                <div className="bottom">Staking 1 - 99999 PLG</div>
              </div>
            </div>
          </motion.div>
          <motion.div
            ref={tRef}
            initial={{ opacity: 0, y: 20 }}
            animate={tControls}
            className="card-wrapper"
          >
            <div className="card">
              <div className="logo-wrapper">
                <img src={cougarIcon} alt="cougar" />
              </div>
              <div className="title">Cougar Tier</div>
              <div className="description">
                <p>35% of the IDO allocation</p>
                <div className="bottom">Staking 100K - 499999 PLG</div>
              </div>
            </div>
          </motion.div>
          <motion.div
            ref={cRef}
            initial={{ opacity: 0, y: 20 }}
            animate={cControls}
            className="card-wrapper"
          >
            <div className="card">
              <div className="logo-wrapper">
                <img src={jaguarIcon} alt="jaguar" />
              </div>
              <div className="title">Jaguar Tier</div>
              <div className="description">
                <p>50% of the IDO allocation</p>
                <div className="bottom">Staking  > 500K PLG</div>
              </div>
            </div>
          </motion.div>
        </StyledAdvantages>
        <StyledAdvantages>
          <motion.div
            ref={aRef}
            initial={{ opacity: 0, y: 20 }}
            animate={aControls}
            className="card-wrapper"
          >
            <div className="card inline">
              <div className="card__left">
                <div className="logo-wrapper">
                  <img src={equiteIcon} alt="equity" />
                </div>
              </div>
              <div className="card__right">
                <div className="title">Fair and Equitable</div>
                <div className="description">
                  <p>
                    Our unique staking and distribution methodology ensures guaranteed participation.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            ref={bRef}
            initial={{ opacity: 0, y: 20 }}
            animate={bControls}
            className="card-wrapper"
          >
            <div className="card inline">
              <div className="card__left">
                <div className="logo-wrapper">
                  <img src={teamworkIcon} alt="team work" />
                </div>
              </div>
              <div className="card__right">
                <div className="title">Community Focused</div>
                <div className="description">
                  <p>The emphasis we place on people and teams ensures a successful start for new projects.</p>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            ref={dRef}
            initial={{ opacity: 0, y: 20 }}
            animate={dControls}
            className="card-wrapper"
          >
            <div className="card inline">
              <div className="card__left">
                <div className="logo-wrapper">
                  <img src={innovationIcon} className="lamp" alt="innovation" />
                </div>
              </div>
              <div className="card__right">
                <div className="title">Innovative Platform</div>
                <div className="description">
                  <p>A Whale-proof launchpad with a robust vetting process and anti-scam protection.</p>
                </div>
              </div>
            </div>
          </motion.div>
        </StyledAdvantages>
 {/*        <StyledAdvantages>
          <StyledMinCard>
            <div className="card">
              <div className="header">
                <img src={equiteIcon} />
                <span>Fair and Equitable</span>
              </div>
              <div className="content">
                Our unique staking and distribution methodology ensures guaranteed participation.
              </div>
            </div>
          </StyledMinCard>
          <StyledMinCard>
            <div className="card">
              <div className="header">
                <img src={teamworkIcon} />
                <span>Community Focused</span>
              </div>
              <div className="content">
                The emphasis we place on people and teams ensures a successful start for new projects.
              </div>
            </div>
          </StyledMinCard>
          <StyledMinCard>
            <div className="card">
              <div className="header">
                <img src={innovationIcon} />
                <span>Innovative Platform</span>
              </div>
              <div className="content">
                A Whale-proof launchpad with a robust vetting process and anti-scam protection.
              </div>
            </div>
          </StyledMinCard>
        </StyledAdvantages> */}
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Tiers
