import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import ContentWrapper from "styles/contentWrapper"
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';

import arrowIcon from "content//images/arrow.svg"


const faqs = [
  {
    question: "Arbitrum - What is it?",
    answer: "Arbitrum is a next-generation layer 2 for Ethereum decentralized applications. Its primary objective is to reduce transaction fees and network congestion."
  },
  {
    question: "How do I add Arbitrum network to metamask wallet?",
    answer: <div>Click on the Metamask extension > click on “Ethereum Mainnet” network > click on “Custom RPC” at the end of the menu, and enter the following:
        <ul>  
          <li>Network Name: Arb1</li>
          <li>RPC: https://arb1.arbitrum.io/rpc</li>
          <li>Chain ID: 42161</li>
          <li>Currency Symbol: ETH</li>
          <li>Block Explorer URL: https://arbiscan.io"</li>
        </ul>
      </div>
  },
  {
    question: "How can Ethereum be transferred to the Arbitrum network?",
    answer: <div>
      <p>
        You can use the bridge on the Arbitrum network: <a href="https://bridge.arbitrum.io" target="_blank">https://bridge.arbitrum.io </a> By sending your Ethereum from Etherchain to Arbichain and paying the fees once, you will have the same amount of Ethereum in your wallet on Arbichain.
      </p>
      <p>
        Alternatively, you may transfer the eth to your wallet directly from binance, and it will show up in your wallet on the Arbichain.
        <a href="https://www.binance.com/en/support/announcement/192e0315a7744dcfb8dede7453884c73" target="blank">Read annoncement here</a>
      </p>
    </div>
  },
  {
    question: "When is the TGE scheduled?",
    answer: "The TGE is scheduled for 12th December.",
  }
]

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  position: relative;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    h2 {
      padding: 0 0 1rem;
      color: white;
    }
    .subtitle {
      max-width: 36.68rem;
      text-align: center;
      color: #11cbed;
    }
  }
`

const StyledContent = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: -1rem;
  margin-right: -1rem;
`

const StyledQuestions = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.poppins};
  li.question {
    direction: ltr;
    background-color: transparent;
    color: #212121;
    width: 100%;
    text-align: left;
    outline: none;
    transition: border-color .4s;
    display: block;
    margin-bottom: 3rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    padding: 2rem;
    .preview {
      font-size: 1.5rem;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      color: white;
      .icon-wrapper {
        width: 3rem;
        height: 3rem;
        min-width: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.12);
        transform: matrix(1, 0, 0, -1, 0, 0);
        img {
          transition: transform .4s;
        }
        img.rotated {
          transform: rotate(180deg);
          transition: transform .4s;
        }
      }
    }
    .content {
      padding-top: 1rem;
      display: none;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #FFFFFF;
      opacity: 0.6;
    }
    .content.shown {
      display: block;
    }

  }
`



const Faq = ({}) => {
  const [openedIndex, setOpendIndex] = useState(0)
  const [ tRef, tInView, tEntry ] = useInView({threshold: 0});
  const [ iRef, iInView, iEntry ] = useInView({threshold: 0});
  const [ cRef, cInView, cEntry ] = useInView({threshold: 0});
  const tControls = useAnimation()
  const iControls = useAnimation()
  const cControls = useAnimation()

  useEffect(() => {
    const sequence = async () => {
      if (iInView) iControls.start({ opacity: 1, y: 0, transition: { delay: 0.2 } })
      if (tInView) tControls.start({ opacity: 1, y: 0, transition: { delay: 0.4 } })
      if (cInView) cControls.start({ opacity: 1, y: 0, transition: { delay: 0.6 } })
    }
    sequence()
  }, [tControls, iControls, cControls, tInView, iInView, cInView])

  return (
    <StyledSection  id="faq">
      <StyledContentWrapper>
          <h2>Frequently Asked Questions</h2>
          <StyledContent>
            <StyledQuestions>
              {faqs.map(({question, answer}, key) => {
                  const contentClass = openedIndex === key ? "content shown" : "content"
                  const opened = openedIndex === key ? true : false
                  return (
                    <li className="question">
                      <div className="preview" onClick={opened ? () => setOpendIndex(null) : () => setOpendIndex(key)}>
                        <span>{question}</span>
                        <div className="icon-wrapper">
                          {opened ? <img src={arrowIcon} className="rotated" /> : <img src={arrowIcon} />}
                        </div>
                      </div>
                      <div className={contentClass}>{answer}</div>
                    </li>
                  )
              })}
            
            </StyledQuestions>
          </StyledContent>
        </StyledContentWrapper>
    </StyledSection>
  )
}


export default Faq
