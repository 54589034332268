import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import { StaticImage } from "gatsby-plugin-image"

import Context from "context/"
import ContentWrapper from "styles/contentWrapper"
import StyledBtn from "styles/button"
import GroupImg from "content/group-hero.png"
import rocketImg from "content/rocket-3d.png"
import meteorImg from "content/meteor.png"
import spingsImg from "content/images/spins.png"
import arbitrumLogo from "content/images/arbitrum.svg"
import ethereumLogo from "content/images/ethereum.svg"
import binanceLogo from "content/images/binance.svg"





const StyledSection = styled.section`
  display: -ms-flexbox;
  background-image: url(${spingsImg});
  background-repeat: no-repeat;
  display: flex;
  -ms-flex-pack: 50%;
  justify-content: 50%;
  -ms-flex-align: center;
  align-items: center;
  height: auto;
  padding-top: 8rem;
  .hero-section {
    position: relative;
    z-index: 4;
    width: 100%;
    text-align: center;
    .section-title {
    }
    
    @media(max-width: 500px) {
      .section-title {
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.5rem;
      }
      .section-subtitle {
        font-size: 1.3rem;
        line-height: 1.5rem;
      }
    }
    .button-wrapper {
      display: flex;
      margin-top: 5rem;
      flex-direction: column;
      align-items: center;
      a:first-of-type {
        margin-right: 0; 
      }
      a:nth-of-type(2) {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }

    .intro-img {
      position: absolute;
      right: 0;
      top: -6rem;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      .rocket {
        position: absolute;
        left: -5rem;
        top: 0;
        transform: translatey(-0px);
	      animation: float 10s ease-in-out infinite;
      }
      .meteor {
        width: 70%;
        height: auto;
        position: absolute;
        left: 6rem;
        top: 2rem;
        -webkit-animation: spin 100s linear infinite;
        -moz-animation: spin 100s linear infinite;
        -ms-animation: spin 100s linear infinite;
        -o-animation: spin 100s linear infinite;
        animation: spin 100s linear infinite;
      }
    }
    .slidingVertical {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.7);
      .item {
        text-align: center;
        padding: 1rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        &:last-of-type {
          border-bottom: none;
        }
      }
      .section-subtitle {
        font-size: 1rem;
      }
    }
    @media (min-width: 1200px) {
      text-align: left;
      .slidingVertical {
        font-size: 1.3rem;
        .item {
          padding: 0;
          border: none;
          text-align: left;
        }
      }
      .section-subtitle {
        font-size: 1.71rem;
        font-weight: 500;
        margin-top: 2rem;
        line-height: 2.85rem;
        text-align: left;
      }
      .button-wrapper {
        flex-direction: row;
        a:first-of-type {
          margin-right: 1rem; 
        }
        a:nth-of-type(2) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .intro-img {
        overflow: visible;
        .rocket {
          left: 0;
        }
        .meteor {
          left: 11rem;
        }
      }
    }
  }
  @media (min-width: 500px) {
    .slidingVertical {
      height: 5rem;
    }
    .slidingVertical .item {
      animation: topToBottom 9s linear infinite 0s;
      -ms-animation: topToBottom 9s linear infinite 0s;
      -webkit-animation: topToBottom 9s linear infinite 0s;
      opacity: 0;
      position: absolute;
      left: 0;
    }
    .slidingVertical .item:nth-child(1){
      animation-delay: 3s;
      -ms-animation-delay: 3s;
      -webkit-animation-delay: 3s;
    }
    .slidingVertical .item:nth-child(2){
      animation-delay: 6s;
      -ms-animation-delay: 6s;
      -webkit-animation-delay: 6s;
    }
    
    .slidingVertical .item:nth-child(3){
      animation-delay: 9s;
      -ms-animation-delay: 9s;
      -webkit-animation-delay: 9s;
    }
    @keyframes topToBottom {
      0% { opacity: 0; }
      5% { opacity: 0; transform: translateY(-20px); }
      20% { opacity: 1; transform: translateY(0px); }
      30% { opacity: 1; transform: translateY(0px); }
      45% { opacity: 0; transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
    }
    /*topToBottom Animation*/
    @-moz-keyframes topToBottom{
      0% { opacity: 0; }
      5% { opacity: 0; -moz-transform: translateY(-20px); }
      20% { opacity: 1; -moz-transform: translateY(0px); }
      30% { opacity: 1; -moz-transform: translateY(0px); }
      45% { opacity: 0; -moz-transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
    }
    @-webkit-keyframes topToBottom{
      0% { opacity: 0; }
      5% { opacity: 0; -webkit-transform: translateY(-20px); }
      20% { opacity: 1; -webkit-transform: translateY(0px); }
      30% { opacity: 1; -webkit-transform: translateY(0px); }
      45% { opacity: 0; -webkit-transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
    }
    @-ms-keyframes topToBottom{
      0% { opacity: 0; }
      5% { opacity: 0; -ms-transform: translateY(-20px); }
      20% { opacity: 1; -ms-transform: translateY(0px); }
      30% { opacity: 1; -ms-transform: translateY(0px); }
      45% { opacity: 0; -ms-transform: translateY(20px); }
      80% { opacity: 0; }
      100% { opacity: 0; }
    }
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    margin-top: 18.5rem;
    @media (min-width: 1200px) {
     margin-top: 5.5rem;
    }
  }
`

const StyledContentFlex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  .intro-text {
    flex: 1 1 100%;
    width: 100%;
    margin-bottom: 2rem;
    position: relative;
  }
  .intro-img {
    flex: 1 1 100%;
    width: 100%;
    z-index: -1;

  }
  @media (min-width: 1200px) {
    flex-direction: row;
    align-items: flex-end;
    .intro-text {
      flex: 1 1 50%;
      width: 50%;
      max-width: 50%;
      margin-bottom: 4rem;
    }
    .intro-img {
      flex: 1 1 54vw;
      width: 54vw;
      margin-top: 0;
    }
  }
`

const StyledLogos = styled.div`
  margin-bottom: 3rem;
`

const StyledButtonPrimary = styled(StyledBtn)`
  color: white;
  background: linear-gradient(94.87deg, #882DB2 8.32%, #C641BE 97.22%);
  padding: 1rem 2.3rem;
  text-align: center;
  height: 4rem;
  width: unset;
`

const StyledButtonEmpty = styled(StyledBtn)`
  color: #D14ABD;
  background: transparent;
  border: 1px solid #D14ABD;
  padding: 1rem 2.3rem;
  text-align: center;
  height: 4rem;
  width: unset;
`

const StyledButton = styled.a`
  color: #212121;
  position: absolute;
  width: 260px;
  bottom: calc(50vh - 9rem);
  left: calc(50% - 130px);
  background-color: #fff;
  border-color: #ffeaef;
  padding: 0.5rem 1.2rem;
  transition: all .5s ease-in-out;
  cursor: pointer;
  height: 3rem;
  font-weight: 700;
  font-size: .688rem;
  line-height: 1.3rem;
  letter-spacing: 1.54px;
  border-radius: 100px;
  border: 1px solid;
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 600;
  line-height: 2rem;
  @media (min-width: 600px) {
    left: calc(50% + 1rem);
    bottom: calc(50vh - 4.5rem);
    width: unset;
  }
  &:hover {
    opacity: 0.8;
  }
  @media (min-width: 1200px) {
    left: calc(14rem + 1rem);
  }
`



const Hero = () => {
  const { isIntroDone, darkMode } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      await gControls.start({
        opacity: 1,
        y: 0,
        transition: { delay: 0.1 },
      })
      // Animate underlining to hover stat
    }
    pageLoadSequence()
  }, [gControls])
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://plungepad-js.s3.eu-west-1.amazonaws.com/hero-animation.js"
    script.async = true
    document.body.appendChild(script)
  }, [])
  return (
    <StyledSection id="hero">
      <div className="hero-section">
        <StyledContentWrapper>
          <StyledContentFlex>
            <div className="intro-text">
              <h1 className="section-title">
                The First & Fairest Multichain Launchpad <span>Powered By Arbitrum</span>
              </h1>
              <div className="section-subtitle slidingVertical">
                <span className="item"><span className="inner"> Multichain launchpad with guaranteed allocation for all stakers</span></span>
                <span className="item"><span className="inner">Native DEX for Arbitrum Ecosystem</span></span>
                <span className="item"><span className="inner">Cross-chain bridge</span></span>
              </div>
              <div className="button-wrapper">
                <StyledButtonPrimary to="https://pancakeswap.finance/swap?outputCurrency=0xDAd3ABce6Fb87FA0355203b692723A7EE8aa9D63" target="_bkank"><span>Buy on PancakeSwap</span></StyledButtonPrimary>
                <StyledButtonEmpty href="https://app.sushi.com/swap?outputCurrency=0xDAd3ABce6Fb87FA0355203b692723A7EE8aa9D63" target="_blank">Buy on SushiSwap</StyledButtonEmpty>
              </div>
            </div>
            <div className="intro-img">
              <div id="lottie"></div>
            </div>
          </StyledContentFlex>
          <StyledLogos>
            <img src={arbitrumLogo} className="mr-2" alt="arbitrum logo" width="207" height="36"></img>
            <img src={ethereumLogo} alt="ethereum logo" width="207" height="36"></img>
            <img src={binanceLogo} alt="binance logo" width="207" height="36"></img>
          </StyledLogos>
        </StyledContentWrapper>
      </div>
    </StyledSection>
  )
}

export default Hero
