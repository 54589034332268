import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import ContentWrapper from "styles/contentWrapper"
import rocketImg from "content/images/rocket-group.png"

import spins from "content/images/spins-banner.png"


const StyledSection = styled.section`
  width: 100%;
  height: auto;
  position: relative;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`

const StyledContent = styled.div`
  background: linear-gradient(94.87deg, #882DB2 8.32%, #C641BE 97.22%);
  border-radius: 4rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.poppins};
  h2 {
    color: white;
    margin-bottom: 1rem;
    font-family: ${({ theme }) => theme.fonts.poppins};
    font-weight: 700;
  }
  .subtitle {
    margin-bottom: 3rem;
    color: white;
    font-size: 1.1rem;
    opacity: 0.6;
    color: white;
  }
  .left {
    flex: 1 1 100%;
    width: 100%;
    padding: 3rem;
    background-image: url(${spins});
    background-repeat: no-repeat;
    background-position: 0 0;
    border-radius: 4rem 4rem 0 0;
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-start;
    a {
      width: 13rem;
      background: white;
      box-sizing: border-box;
      border-radius: 4.36rem;
      color: #121212;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
  .right {
    flex: 1 1 100%;
    width: 100%;
    position: relative;
    height: 25rem;
    .rocket {
      position: absolute;
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    .left {
      padding: 5rem;
      flex: 1 1 45%;
      width: 45%;
      border-radius: 4rem 0 0 4rem;
      background-size: 26rem;
    }
    .right {
      height: unset;
      flex: 1 1 55%;
      width: 55%;
      .rocket {
        position: absolute;
        top: -1rem;
        right: 3rem;
        width: 79%;
      }
    }
  }
`



const Banner = ({}) => {
  return (
    <StyledSection  id="banner">
      <StyledContentWrapper>
          <StyledContent>
            <div className="left">
              <h2>Plunge Pad</h2>
              <div className="subtitle">
                We are here to assist you in getting your project off the ground
              </div>
              <div className="button-wrapper">
                <Link href="https://forms.zohopublic.eu/plungepadio/form/ApplicationforanIDO/formperma/NBQbo7aqdwTVBSK8Ov2D99tWzfHADdp7i36bFhS3LJM" target="blank" className="plunge-button">Apply Now</Link>
              </div>
            </div>
            <div className="right">
              <img className="rocket" src={rocketImg} />
            </div>
            
          </StyledContent>
        </StyledContentWrapper>
    </StyledSection>
  )
}


export default Banner
