import React, { useRef, useContext, useEffect } from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"

import { useOnScreen } from "hooks/"
import Context from "context/"
import ContentWrapper from "styles/contentWrapper"

import ImageAbout from "content/images/ab2.png"
import GradientBar from "content/images/gradient-bar.png"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 3rem 0;
  padding-bottom: 17rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: ${props => props.reversed ? '3rem' : "0" };
    h2 {
      padding: 0;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: ${props => props.reversed ? 'row' : 'row-reverse' };
      justify-content: space-between;
    }
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .text-content {
      width: 100%;
      max-width: 40.25rem;
      .label {
        font-size: 1.3rem;
        line-height: 2rem;
        color: #C641BE;
        font-family: ${({ theme }) => theme.fonts.primary};
        margin-right: 1rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        img {
          margin-right: 1rem;
        }
      }
      .title {
        margin-bottom: 1.5rem;
        color: white;
      }
      p {
        color: white;
        opacity: 0.6;
      }
    }
    .image-content {
      width: 100%;
      max-width: 35rem;
      margin-top: 4rem;
      margin-left: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left:  ${props => props.reversed ? '2rem' : '0' };
        margin-right:  ${props => props.reversed ? '0' : '2rem' };
      }
    }
    .about-author {
      width: 100%;
      border-radius: ${({ theme }) => theme.borderRadius};
      transition: all 0.3s ease-out;
      /* filter: grayscale(20%) contrast(1) brightness(90%);
      &:hover {
        filter: grayscale(50%) contrast(1) brightness(90%);
        transform: translate3d(0px, -0.125rem, 0px);
      } */
    }
  }
`

const AboutusReversed = () => {
  const { isIntroDone } = useContext(Context).state
  const tControls = useAnimation()
  const iControls = useAnimation()
  // Required for animating the text content
  const tRef = useRef()
  const tOnScreen = useOnScreen(tRef)

  // Required for animating the image
  const iRef = useRef()
  const iOnScreen = useOnScreen(iRef)

  // Only trigger animations if the intro is done or disabled
  useEffect(() => {
    if (isIntroDone) {
      if (tOnScreen) tControls.start({ opacity: 1, y: 0 })
      if (iOnScreen) iControls.start({ opacity: 1, x: 0 })
    }
  }, [isIntroDone, tControls, iControls, tOnScreen, iOnScreen])

  return (
    <StyledSection id="about" reversed={false}>
      <StyledContentWrapper reversed={false}>
        <motion.div
          className="inner-wrapper"
          ref={tRef}
          initial={{ opacity: 0, y: 20 }}
          animate={tControls}
        >
          <div className="text-content">
            <div className="label"><img src={GradientBar} alt="gradient bar" /><span>WE STAND OUT</span></div>
            <h2 className="title">WHAT MAKES US DIFFERENT</h2>
            <p>
              In contrast to other platforms, there is no minimum $PLG staking requirement.
              You can stake as little as one token and you will receive a guaranteed proportionate allocation in the IDOs on the platform.
            </p>
            <p>
              Plunge Pad is more than just a launchpad, we are a full-fledged platform for the arbitrum ecosystem.
              We are building cross-chain bridges and are developing a native DEX to support Arbitrum.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="image-content"
          ref={iRef}
          initial={{ opacity: 0, x: 20 }}
          animate={iControls}
        >
          <img
            className="about-author"
            src={ImageAbout}
            alt="image team"
          />
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

export default AboutusReversed
