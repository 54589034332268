import React, { useEffect, useContext } from "react"
import styled from "styled-components"
import ContentWrapper from "styles/contentWrapper"
import arbitrumLogo from "content/arbitrum-full.png"
import bscLogo from "content/binance.png"
import ethereumLogo from "content/ethereum.png"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: white;
  padding-top: 6rem;
  padding-bottom: 3rem;
  position: relative
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 3.3rem;
  background: rgba(254, 254, 254, 0.3);
  border: 0.5px solid #E4E4E4;
  box-shadow: 0px 0.29rem 3.2rem rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(2.61rem);
  border-radius: 2.18rem;
  .item {
    width: 15rem;
    margin:  4rem  3.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 15rem;
      height: auto;
    }
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    .item {
      flex: 1 1 33.3333333%;
      width: 33.3333333%;
    }
  }
`

const Ecosystem = () => {
  return (
    <StyledSection  id="ecosystem">
      <StyledContentWrapper>
        <StyledContent>
          <a href="https://arbitrum.io/" target="_blank" className="item">
            <img src={arbitrumLogo} />
          </a>
          <a href="https://ethereum.org/en/" target="_blank" className="item">
            <img src={ethereumLogo} />
          </a>
          <a href="https://www.binance.org/en/smartChain" target="_blank" className="item">
            <img src={bscLogo} />
          </a>
        </StyledContent>
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Ecosystem