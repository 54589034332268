import React, { useRef, useContext, useEffect } from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import { useInView } from 'react-intersection-observer';

import { useOnScreen } from "hooks/"
import Context from "context/"
import ContentWrapper from "styles/contentWrapper"

import ImageAbout from "content/images/ab1.png"
import GradientBar from "content/images/gradient-bar.png"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 3rem 0;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: ${props => props.reversed ? '3rem' : "0" };
    h2 {
      padding: 0;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: ${props => props.reversed ? 'row' : 'row-reverse' };
      justify-content: space-between;
    }
    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .text-content {
      width: 100%;
      max-width: 40.25rem;
      .label {
        font-size: 1.3rem;
        line-height: 2rem;
        color: #C641BE;
        font-family: ${({ theme }) => theme.fonts.primary};
        margin-right: 1rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        img {
          margin-right: 1rem;
        }
      }
      .title {
        margin-bottom: 1.5rem;
        color: white;
      }
      p {
        color: white;
        opacity: 0.6;
      }
    }
    .image-content {
      width: 100%;
      max-width: 35rem;
      margin-top: 4rem;
      margin-left: 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        margin-left:  ${props => props.reversed ? '2rem' : '0' };
        margin-right:  ${props => props.reversed ? '0' : '2rem' };
      }
    }
    .about-author {
      width: 100%;
      border-radius: ${({ theme }) => theme.borderRadius};
      transition: all 0.3s ease-out;
    }
  }
`

const Aboutus = () => {
  const { isIntroDone } = useContext(Context).state

  // Required for animating the text content


  const [ iRef, iInView, iEntry ] = useInView({threshold: 0});
  const [ tRef, tInView, tEntry ] = useInView({threshold: 0});
  const tControls = useAnimation()
  const iControls = useAnimation()


  // Only trigger animations if the intro is done or disabled
  useEffect(() => {
    if (isIntroDone) {
      if (tInView) tControls.start({ opacity: 1, y: 0 })
      if (iInView) iControls.start({ opacity: 1, x: 0 })
    }
  }, [isIntroDone, tControls, iControls, tControls, iInView])

  return (
    <StyledSection id="about" reversed={true}>
      <StyledContentWrapper reversed={true}>
        <motion.div
          className="inner-wrapper"
          ref={tRef}
          initial={{ opacity: 0, y: 20 }}
          animate={tControls}
        >
          <div className="text-content">
            <div className="label"><img src={GradientBar} alt="gradient bar" /><span>PLUNGE PAD</span></div>
            <h2 className="title">WHAT IS IT?</h2>
            <p>
              Plunge Pad is the first and the fairest platform for launching projects on Arbitrum. 
              We are not only the first launchpad for Arbitrum, but also the first launchpad to offer a guaranteed allocation to each token holder in the IDO.
            </p>
            <p>
              Plungepad's decentralized and interoperable platform will enable the launch of 
              carefully vetted projects on the Arbitrum platform to raise capital and drive initial liquidity.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="image-content"
          ref={iRef}
          initial={{ opacity: 0, x: 20 }}
          animate={iControls}
        >
          <img
            className="about-author"
            src={ImageAbout}
            alt="image team"
          />
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

export default Aboutus
