import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import ContentWrapper from "styles/contentWrapper"

import r8Logo from "content/r8.png"
import oigLogo from "content/oig.png"
import daostarterLogo from "content/daostarter.png"
import eraxLogo from "content/erax.png"
import moonwhaleLogo from "content/moonwhale.png"
import balenaLogo from "content/balena.png"
import gamefiLogo from "content/gamefi.png"
import alphaHuntLogo from "content/alpha-hunt.png"
import deltaCapitalLogo from "content/delta-capital.png"
import rikxCapitalLogo from "content/rikx-capital.png"


const StyledSection = styled.section`
  width: 100%;
  height: auto;
  position: relative;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    h2 {
      text-align: center;
      color: white;
    }
    .subtitle {
      max-width: 36.68rem;
      text-align: center;
      color: #11cbed;
    }
  }
`

const StyledContent = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: -1rem;
  margin-right: -1rem;
  .card {
    color: #11cbed;
    position: relative;
    text-align: center;
    height: 100%;
    max-width: 100%;
    border-radius: 0.5rem;
    background: transparent;
    border-style: solid;
    border-color: #2e578e;
    border-image: initial;
    border-width: 3px;
    padding: 1.5rem;
    -webkit-transform: perspective(100px);
    -ms-transform: perspective(100px);
    transform: perspective(100px);
    margin: 1rem;
    width: 100%;
    flex: 1 1 100%;
    &:nth-of-type(1) {
      border-top-width: 3px;
      .header {
        &:after {
          background: #87986a;
        }
      }
      .title {
        color: #87986a;
      }
      .bottom {
        &:before {
          background-color: #87986a;
        }
      }
    }
    &:nth-of-type(2) {
      border-top-width: 3px;
      .header {
        &:after {
          background: #52BD94;
        }
      }
      .title {
        color: #52BD94;
      }
      .bottom {
        &:before {
          background-color: #52BD94;
        }
      }
    }
    &:nth-of-type(3) {
      border-top-width: 3px;
      .header {
        &:after {
          background: #563cc9;
        }
      }
      .title {
        color: #563cc9;
      }
      .bottom {
        &:before {
          background-color: #563cc9;
        }
      }
    }
    .header {
      padding: 1rem;
      position: relative;
      font-size: 1rem;
      line-height: 1.73rem;
      letter-spacing: .5px;
      color: white;
      &:after {
        width: 10rem;
        height: 2px;
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
    .logo-wrapper {
      width: 12rem;
      height: 12rem;
      border-radius: 100%;
      margin: auto;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: auto;
        height: 12rem; 
      }
    }
    .title {
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
    .bottom {
      padding: 1rem;
      position: relative;
      font-size: 1rem;
      line-height: 1.73rem;
      letter-spacing: .5px;
      color: white;
      &:before {
        width: 10rem;
        height: 2px;
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    .card {
      flex: 1 1 33.3333333%;
      width: 33.3333333%;
    }
  }
`


const StyledItems = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: -15px;
  margin-right: -15px;
  .item {
    padding: 1rem;
    background: #2F1D68;
    border-radius: 1.4rem;
    width: 17rem;
    margin: 1rem;
    .content {
      height: 8rem;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 2rem;
      img {
        max-width: 12rem;
      }
    }
  }
  .item.ecosystem {
    .content {
      background: rgba(255,255,255,1);
    }
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`


const Partners = ({}) => {
  return (
    <StyledSection  id="partners">
      <StyledContentWrapper>
        <h2>Partners and Investors</h2>
        <StyledContent>
            <StyledItems>
              <a  href="https://www.daostarter.pro/" target="_blank" className="item">
                <div className="content">
                  <img src={daostarterLogo} />
                </div>
              </a>
              <a href="https://www.r8.capital/" target="_blank" className="item">
                <div className="content">
                  <img src={r8Logo} />
                </div>
              </a>
              <a href="https://oiginvest.com/" target="_blank" className="item">
                <div className="content">
                  <img src={oigLogo} />
                </div>
              </a>
              <a href="https://erax.io/" target="_blank" className="item">
                <div className="content">
                  <img src={eraxLogo} />
                </div>
              </a>
               <a href="https://moonwhale.ventures/" target="_blank" className="item">
                <div className="content">
                  <img src={moonwhaleLogo} />
                </div>
              </a>
              <a href="https://gameficapital.net/" target="_blank" className="item">
                <div className="content">
                  <img src={gamefiLogo} style={{maxWidth: '10rem'}}/>
                </div>
              </a>
              <a href=" https://www.balena.capital/" target="_blank" className="item">
                <div className="content">
                  <img src={balenaLogo} />
                </div>
              </a>
              <a href="https://alphahunt.club/" target="_blank" className="item">
                <div className="content">
                  <img src={alphaHuntLogo} />
                </div>
              </a>
              <a href="https://www.delacapital.com/" target="_blank" className="item">
                <div className="content">
                  <img src={deltaCapitalLogo} style={{maxWidth: '10rem'}}/>
                </div>
              </a>
              <a href="https://rikxcapital.com/" target="_blank" className="item">
                <div className="content">
                  <img src={rikxCapitalLogo} />
                </div>
              </a>
            </StyledItems>
        </StyledContent>
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Partners
