import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import ContentWrapper from "styles/contentWrapper"

import stage1 from "content/images/stage1.svg"
import stage2 from "content/images/stage2.svg"
import stage3 from "content/images/stage3.svg"
import stage4 from "content/images/stage4.svg"


const StyledSection = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  h2 {
    text-align: center;
    color: white;
  }
  .subtitle {
    max-width: 36.68rem;
    text-align: center;
    color: #11cbed;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    max-width: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 3rem;
    padding: 3rem 0;
    .divider-line {
      border: 3px solid #D14ABD;
      background: #D14ABD;
      margin-top: 6rem;
      width: 100%;
      display: none;
    }
    .list-circles {
      list-style: none;
      display: none;
      justify-content: space-between;
      width: 89.57rem;
      margin: 0;
      padding: 0;
      .list-item {
        width: 25%;
        display: flex;
        justify-content: flex-start;
      }
      .circle {
        width: 4.4rem;
        height: 4.4rem;
        background: rgba(209, 74, 189, 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -2.2rem;
        span {
          width: 3rem;
          height: 3rem;
          background: #D14ABD;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: ${({ theme }) => theme.fonts.poppins};
          font-weight: 700;
        }
      }
    }
    @media (min-width: 1200px) {
      .divider-line  {
        display: block;
      }
      .list-circles  {
        display: flex;
      }
    }
  }
`

const StyledFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 3rem;
  flex-wrap: wrap;
  .card {
    width: 19rem;
    background: #2F1D68;
    border-radius: 1.5rem;
    margin-right: 0;
    margin-bottom: 1rem;
    .title {
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      padding: 1.1rem 2rem;
      img {
        margin-right: 1rem;
        vertical-align: sub;
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 3rem;
      .item {
        text-align: left;
      }
    }
  }
  @media (min-width: 1200px) {
    width: 89.57rem;
    justify-content: flex-start;
    .card {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }
`

const Roadmap = ({}) => {
  return (
    <StyledSection  id="roadmap2">
      <h2>Roadmap</h2>
      <StyledContentWrapper>
        <div className="divider-line"></div>
        <ul className="list-circles">
          <li className="list-item">
            <div className="circle"><span>1</span></div>
          </li>
          <li className="list-item">
            <div className="circle"><span>2</span></div>
          </li>
          <li className="list-item">
            <div className="circle"><span>3</span></div>
          </li>
          <li className="list-item">
            <div className="circle"><span>4</span></div>
          </li>
        </ul>
        <StyledFlex>
          <div className="card">
            <div className="title"><img src={stage1} alt="stage 1" />Stage 1</div>
            <ul className="list">
              <li className="item">Team formation</li>  
              <li className="item">Concept</li>
              <li className="item">Market research </li>
              <li className="item">Current launchpads assessment</li>
            </ul>
          </div>
          <div className="card">
            <div  className="title"><img src={stage2} alt="stage 2" />Stage 2</div>
            <ul className="list">
              <li className="item">Website launch</li>  
              <li className="item">Light paper</li>
              <li className="item">Smart contracts developement</li>
              <li className="item">MVP (Arbitrum beta)</li>
              <li className="item">Project listings</li>
              <li className="item">Liquidity Mining</li>
              <li className="item">Anti-scam contract verification</li>
              <li className="item">Tiers development</li>
              <li className="item">Full KYC integration</li>
            </ul>
          </div>
          <div className="card" >
            <div  className="title"><img src={stage3} alt="stage 3" />Stage 3</div>
            <ul className="list">
              <li className="item">Social media platforms</li>  
              <li className="item">Token sale</li>
              <li className="item">Marketing</li>
              <li className="item">Partnerships and incubation</li>
              <li className="item">Staking Mechanism</li>
              <li className="item">Community powered featured project voting</li>
            </ul>
          </div>
          <div className="card" >
            <div  className="title"><img src={stage4} alt="stage 4" />Stage 4</div>
            <ul className="list">
              <li className="item">Governance model and DAO</li>  
              <li className="item">Swapping platform</li>
              <li className="item" style={{ boxShadow: 'none' }}>Market research </li>
              <li className="item" style={{ boxShadow: 'none' }}>Cross-chain bridge and native Arbitrum DEX</li>
            </ul>
          </div>
        </StyledFlex>
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Roadmap
