import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { injected } from "components/wallet/connectors"
import { useWeb3React } from "@web3-react/core"
import ContentWrapper from "styles/contentWrapper"
import StyledBtn from "styles/button"
import nomalandIcon from "content/images/nomaland-icon.png"
import nomalandLogo from "content/images/nomaland-logo.png" 
import arbiIcon from "content/arbitrum.svg"
import bscIcon from "content/bsc.svg"


import rect1 from "content/images/rect1.png"





const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      padding: 0 0 1rem;
      color: white;
    }
    .subtitle {
      margin: auto;
      opacity: 0.6;
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }
`

const StyledFlexInline = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  alogn-items: center;
  margin-bottom: 2em;
  .stats {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      &:nth-of-type(2) {
        margin: 0;
      }
      .logo-wrapper {
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 50%;
        margin-right: 1rem;
        img {
          width: 1.5rem;
          height: auto;
        }
      }
      .details {
        font-weight: 600;
        font-family: ${({ theme }) => theme.fonts.poppins};
        .key {
          font-size: 0.9rem;
        }
        .value {
          font-size: 1.7rem;
          line-height: 2.2rem;
          color: #D14ABD;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .stats {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      li {
        &:nth-of-type(2) {
          margin: 0 2rem;
        }
      }
    }
  }
`

const StyledFlex = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(21.25rem,1fr));
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 5rem;
  .card {
    border-radius: 1.8rem;
    background: rgba(255, 255, 255, 0.06);
    position: relative;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      .hexagon {
        color: rgba(255, 255, 255, 0.6);
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
      }
      .img-bg  {
        transform: scale(1.2);
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
      }
    }
    &__header {
      display: flex;
      border-radius: 0.5rem 0.5rem 0 0;
      position: relative;
      height: 13.125rem;
      overflow: hidden;
      width: 100%;
      margin-bottom: 1.25rem;
      border-radius: 1.8rem;
      padding: 2rem;
      font-weight: 600;
      background: black;
      .img-bg {
        width: 69%;
        height: auto;
        position: absolute;
        left: 15%;
        top: 28%;
        z-index: 0;
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
      }
      .mask {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1!important;
        background: linear-gradient(180deg, rgba(33, 36, 50, 0.6) 0%, rgba(33, 36, 50, 0) 100%);
      }
      .basic {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-family: ${({ theme }) => theme.fonts.poppins};
        z-index: 2;
        .chain {
          width: 4rem;
          height: 4rem;
          min-width: 4rem;
          background: white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 2rem;
            height: auto;
          }
        }
        .name {
          font-size: 1.8rem;
          line-height: 2.2rem;
        }
      }
    }
    &__logo {
      display: flex;
      align-items: center;
      margin-left: 1.125rem;
      margin-top: -5rem;
      position: relative;
      .hexagon {
        width: 6rem;
        display: inline-block;
        color: white;
        filter: url('#goo');
        -webkit-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
        .img-wrapper {
          width: 6rem;
          height: 7rem;
          position: absolute;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 3rem;
            width: 3rem;
            border-radius: 0.5rem;
          }
        }
      }
      
      .hexagon::before {
        content: "";
        display: block;
        background:currentColor;
        padding-top: 115%; /* 100%/cos(30)  */
        clip-path: polygon(0% 25%,0% 75%,50% 100%,100% 75%,100% 25%,50% 0%);
      }
    }
    &__body {
      padding: 2rem;
      font-size: 1.1rem;
      line-height: 1.7rem;
      font-weight: 400;
      font-family: ${({ theme }) => theme.fonts.poppins};
      a {
        color: rgba(255, 255, 255, 0.5);
        margin-left: 0.5rem;
      }
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        .divider {
          border: 1px dashed rgba(255, 255, 255, 0.24);
          margin: 1rem 0;
        }
        li {
          display: flex;
          justify-content: space-between;
          span:first-of-type {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
      .ell {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      .social {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .social-icons {
          font-size: 1.3rem;
          a:nth-of-type(2) {
            margin: 0 2rem;
          }
          svg {
            vertical-align: middle;
          }
        }
      }
      .info {
        margin-top: 2.5rem;
      }
    }
  }
  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StyledState = styled.div`
  border-radius: 10rem;
  display: flex;
  font-size: 1rem;
  line-height: 1rem;
  align-items: center;
  height: 3rem;
  padding: 0 1.3rem;
  text-transform: uppercase;
  width: fit-content;
  margin-left: 1rem;
  background: #252525;
  color: #FCFCFC;
  .dot {
    display: block;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    background: red;
    margin-right: 0.5rem;
  }
  @media (min-width: 1200px) {
    padding: 0 2rem;
    height: 4rem;
  }
`

const StyledButtonPrimary = styled(StyledBtn)`
  width: 6rem;
  height: 2.5rem;
  background: #5932E6;
  border-radius: 5.2rem;
  justify-content: center;
`



const OngoingIdos = ({}) => {
  return (
      <StyledSection  id="pools">
        <StyledContentWrapper>
          <StyledFlexInline>
            <div className="title">
              <h2>Upcoming Projects</h2>
            </div>
          </StyledFlexInline>
          <StyledFlex>
          <Link className="card-wrapper" to="/nomaland-bsc-pool">
              <div className="card">
                <div className="card__header">
                  <img className="img-bg" src={nomalandLogo} alt="nomaland shape 1" />
                  <div className="mask"></div>
                  <div className="basic">
                    <div>
                      <div className="name">Nomaland</div>
                      <div className="price">0.25 BUSD</div>
                    </div>
                    <div className="chain">
                      <img src={bscIcon} />
                    </div>
                  </div>
                </div>
                <div className="card__logo">
                  <div className="hexagon">
                  <div className="img-wrapper">
                    <img src={nomalandIcon} />
                  </div>
                  </div>
                  <svg style={{visibility: 'hidden', position: 'absolute'}} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                          <filter id="goo"><feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />    
                              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                              <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
                          </filter>
                      </defs>
                  </svg>
                </div>
                <div className="card__body">
                  <div className="social">
                    <StyledButtonPrimary as="div">Upcoming</StyledButtonPrimary>
                  </div>
                  <p>
                    Nomadland is a blockchain-based NFTs Action Role-Playing & Strategy game built on Unreal Engine...
                    <Link>Read More</Link>
                  </p>
                  <ul className="info">
                    <li>
                      <span>Start date</span>
                      <span>14:00 UTC - 29 APR</span>
                    </li>
                    <div className="divider"></div>
                    <li>
                      <span>Hard cap</span>
                      <span>50,000 BUSD</span>
                    </li>
                    <div className="divider"></div>
                    <li>
                      <span>Round Type</span>
                      <span>Public</span>
                    </li>
                  </ul>
                </div>
              </div>
            </Link>
          </StyledFlex>
        </StyledContentWrapper>
      </StyledSection>
  )
}


export default OngoingIdos
