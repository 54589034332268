import React, {useEffect} from "react"
import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/hero"
import Ecosystem  from "../components/sections/ecosystem"
import Aboutus from "components/sections/about-us"
import AboutusReversed from "components/sections/about-us-reversed"
import Launchpads from "components/sections/launchpads"
import Idos from "components/sections/idos"
import OngoingIdos from "components/sections/ongoingIdo"
import Team from "components/sections/team"
import Tiers from "components/sections/tiers"
import Partners from "components/sections/partners"
import Roadmap from "components/sections/roadmap"
import Faq from "components/sections/faq"
import Banner from "components/sections/banner"

import { isSSR } from "utils"

import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'

function getLibrary(provider) {
  return new Web3(provider)
}

const IndexPage = (props) => {
  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

/*   useEffect(() => {
    if(!isSSR) {
      const version = localStorage.getItem('version')
      if(version != 4) {
        async function setVersion() {
          try {
            await  localStorage.setItem('version', 4)
          } catch (ex) {
            console.log(ex)
          }
        }
       setVersion().then(res => window.location.reload(true))
      }
    }
  }, []) */

  return (
    <Web3ReactProvider id="main" getLibrary={getLibrary}>
      <GlobalStateProvider initialState={globalState}>
        <Layout {...props} home currentChain={["42161", "56"]}>
          <SEO/>
          <Hero/>
          <div class="ellipsis-bg">
            <Aboutus />
            <AboutusReversed />
          </div>
          <Tiers />
          {/* <OngoingIdos /> */}
          {/* <Idos /> */}
          <Launchpads />
          <Team />
          <Partners />
          <Roadmap />
          <Faq />
          <Banner />
        </Layout>
      </GlobalStateProvider>
    </Web3ReactProvider>

  )
}

export default IndexPage