import React, { useEffect, useContext } from "react"
import styled from "styled-components"
import ContentWrapper from "styles/contentWrapper"
import arbitrumLogo from "content/arbitrum-full.png"
import bscLogo from "content/binance.png"
import ethereumLogo from "content/ethereum.png"
import cryptoBotterAvatar from "content/cryptoBotter.png"
import cryptoBlocksAvatar from "content/cryptoBlokks.png"
import samAvatar from "content/sam.png"
import spin from "content/red-spin.png"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
  .spin {
    position: absolute;
    top: 0;
    left: 0;
    width: 3rem;
  }
  @media (min-width: 600px) {
    .spin {
      width: unset;
    }
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      text-align: center;
      color: white;
    }
    .subtitle {
      max-width: 36.68rem;
    }
  }
`

const StyledFlex = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(21.25rem,1fr));
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 6rem;
  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StyledItem = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1.5rem;
  padding: 2rem;
  .stage {
      text-align: left;
      width: 100%;
    .title {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 700;
    }
    .list {
      margin: 0;
      padding: 0;
      margin-top: 1rem;
      .item {
        padding: 0.75rem 1rem;
        border-radius: 0.375rem;
        box-shadow: 0 70px 140px 0 rgb(130 136 171 / 20%);
        margin-bottom: 0.5rem;
        border: 2px solid #49e9fb;
        list-style: none;
      }
    }
  }
  .member {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar {
      width: 8rem;
      height: 8rem;
      border: 4px solid #ccc;
      border-radius: 50%;
      margin-bottom: 1rem;
      margin-top: -5rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .about {
      font-style: italic;
      padding: 0 1rem;
      color: rgba(255, 255, 255, 0.5);
      a {
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
`

const Team = () => {
  return (
    <StyledSection  id="Team">
      <StyledContentWrapper style={{marginTop: '3rem'}}>
        <h2>About the team</h2>
        <StyledFlex>
          <StyledItem>
            <div className="member">
              <div className="avatar">
                <img src={samAvatar} width="150px" height="150px" alt="member 3" />
              </div>
              <p className="about">
                <a href="https://t.me/@PlungePadann" target="_blank" rel="nofollow">Sam</a> has worked in the cryptocurrency sector for five years and has invested in and helped raise funds for several tier 1 projects. Previously,
                he worked in investment banking for ten years and is well connected with the actual financial sector.
              </p>
            </div>
          </StyledItem>
          <StyledItem>
            <div className="member">
              <div className="avatar">
                <img src={cryptoBlocksAvatar} width="150px" height="150px" alt="member 1" />
              </div>
              <p className="about">
              <a href="https://t.me/Crypto_Blokks" target="_blank" rel="nofollow">Crypto_Blokks</a> is a tech guru, an entrepreneur, a lead software engineer for a high-end company,
                has assisted various crypto projects technically, backed and invested in some of the top 50 coins.
              </p>
            </div>
          </StyledItem>
          <StyledItem>
            <div className="member">
              <div className="avatar">
                <img src={cryptoBotterAvatar} width="150px" height="150px" alt="member 2" />
              </div>
              <p className="about">
              <a href="https://t.me/Crypto_botter" target="_blank" rel="nofollow">Crypto_botter</a> is a semi-retired cybersecurity engineer and developer of blockchain applications.
                His passion for the blockchain drives him to create the best applications possible. He has contributed to numerous crypto projects.
              </p>
            </div>
          </StyledItem>
        </StyledFlex>
      </StyledContentWrapper>
    </StyledSection>
  )
}


export default Team
